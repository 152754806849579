// noinspection CssUnusedSymbol

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'
import { actions, paths } from 'mmfintech-backend-api'

import { PaymentMethodEnum } from 'mmfintech-commons-types'

import applicationFeeImage from '../../../images/app-fee-no-funds-image.png'

import BankWebServiceIcon from '../../../images/icons/bank-web-service-icon.svg?react'
import PaymentNetworkIcon from '../../../images/icons/payment-network-icon.svg?react'
import ReferenceIcon from '../../../images/icons/reference-icon.svg?react'
import TransferDetailsIcon from '../../../images/icons/transfer-details-icon.svg?react'
import SepaIcon from '../../../images/icons/sepa-icon.svg?react'
import SwiftIcon from '../../../images/icons/swift-icon.svg?react'
import ArrowIcon from '../../../images/icons/arrow-right-small-grey.svg?react'

interface ApplicationFeeDetailsProps {
  amount: number
  currency: string
}

const paymentSteps = [
  {
    icon: <PaymentNetworkIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_1',
      'Choose the payment network of your preference - <b>SWIFT</b> or <b>SEPA</b>'
    )
  },
  {
    icon: <TransferDetailsIcon />,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.STEP_2', 'Our bank transfer details will appear on the screen ')
  },
  {
    icon: <BankWebServiceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_3',
      'Log into your bank’s web service (or go to your bank) to transfer the funds'
    )
  },
  {
    icon: <ReferenceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_4',
      'Don’t forget to add the <b>reference</b>. It will help us process your deposit faster'
    )
  }
]

const ApplicationFeeDetailsModal = ({ amount, currency }: ApplicationFeeDetailsProps) => {
  const { merchant, depositError, depositPreviewError } = useSelector(
    ({ banking: { depositError, depositPreviewError }, user: { merchant } }: any) => ({
      merchant,
      depositError,
      depositPreviewError
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { entityType } = merchant || {}

  const handleSelectPaymentOption = (paymentType: string) => {
    if (merchant) {
      if (currency && entityType !== 'INDIVIDUAL') {
        dispatch(
          actions.banking.depositPreview(
            { amount, currency, paymentMethod: paymentType },
            () => {
              dispatch(
                actions.banking.depositInitiate({ amount, currency, paymentMethod: paymentType }, () =>
                  history.push({
                    pathname: paths.banking.depositPreview(),
                    state: { paymentType }
                  })
                )
              )
            },
            false
          )
        )
      }
    }
  }
  return (
    <ApplicationFeeDetailsWrapper>
      <img src={applicationFeeImage} alt='application-fee' />
      <div className='title'>
        {tr(
          'FRONTEND.APPLICATION_FEE.DETAILS.TITLE',
          'The Guardian Bank won’t take money from your bank automatically'
        )}
      </div>
      <div className='subtitle'>
        {tr('FRONTEND.APPLICATION_FEE.DETAILS.SUBTITLE', 'You’ll need to do this bit yourself to pay by bank transfer')}
      </div>
      {paymentSteps.map(({ icon, text }, i) => (
        <PaymentStep key={i}>
          {icon}
          <div>{parse(text)}</div>
        </PaymentStep>
      ))}
      <ErrorDisplay
        error={
          depositError || depositPreviewError
            ? {
                ...(depositError ? depositError : depositPreviewError),
                error: 'The payment method is currently unavailable.',
                errorKey: 'MERCHANTS.ERROR.PAYMENT_METHOD_UNAVAILABLE'
              }
            : null
        }
      />
      <div className='buttons-container'>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.SWIFT_BANKWIRE)}>
          <div className='left'>
            <SwiftIcon />
            <span>SWIFT</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.BANKWIRE)}>
          <div className='left'>
            <SepaIcon />
            <span>SEPA</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
      </div>
    </ApplicationFeeDetailsWrapper>
  )
}

export default ApplicationFeeDetailsModal

const PaymentMethodButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  border: 1px solid rgba(133, 160, 173, 0.3);
  border-radius: 4px;

  flex: 1;
  padding: 1rem;

  cursor: pointer;

  .left {
    font-size: 1.4rem;
    line-height: 1.7rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
`
const PaymentStep = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  width: 100%;

  font-size: 1.4rem;
  line-height: 1.7rem;
`

const ApplicationFeeDetailsWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 3rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: center;
  }
  .subtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #85a0ad;
  }
  .buttons-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
  }
`
