import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'

import { paths } from 'mmfintech-backend-api'
import { Button } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'

import JumpingButton from '../../../components/JumpingButton'

import DepositIcon from '../../../images/icons/welcome-deposit.svg?react'
import ExchangeIcon from '../../../images/icons/welcome-exchange.svg?react'
import SendIcon from '../../../images/icons/welcome-send.svg?react'
import BookMeeting from '../../../images/icons/welcome-book-meeting.svg?react'
import Pointer from '../../../images/icons/welcome-pointer.svg?react'

const WelcomeModal = () => {
  const { modalHide } = useContext(GlobalContext)

  const { merchant } = useSelector(
    ({ user: { merchant } }) => ({
      merchant
    }),
    shallowEqual
  )

  const history = useHistory()

  const { entityType } = merchant || {}

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    modalHide()
  }

  const handleSendMoneyClick = () => {
    history.push(paths.banking.send())
    modalHide()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    modalHide()
  }

  return (
    <>
      <ModalContainer>
        <WelcomeModalWrapper data-test='welcome-modal'>
          <div className='welcome-modal-content'>
            <h1 className='title'>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to The Guardian Bank!')}</h1>

            <div className='welcome-text'>
              {tr(
                'FRONTEND.WELCOME.MODAL.ACTIONS.TEXT',
                'Your can now deposit, exchange and send your Crypto on the go!'
              )}
            </div>

            <ButtonWrapper>
              <JumpingButton
                icon={<DepositIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                onClick={handleDepositClick}
                data-test='button-deposit'
              />
              <JumpingButton
                icon={<ExchangeIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
                onClick={handleExchangeClick}
                data-test='exchange-button'
              />
              <JumpingButton
                icon={<SendIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
                onClick={handleSendMoneyClick}
                data-test='send-money-button'
              />
            </ButtonWrapper>
            <div className='welcome-text italic'>
              {tr(
                'FRONTEND.WELCOME.MODAL.COMPLETE.TEXT',
                'Complete your account to get access to many additional features!'
              )}
            </div>
            <div className='buttons-wrapper'>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
                color='primary'
                onClick={() => {
                  modalHide()
                }}
              />
            </div>
          </div>
        </WelcomeModalWrapper>
        {entityType === 'COMPANY' && (
          <BookMeetingWrapper>
            <BookMeeting className='calendar' />
            <div className='book-left-side'>
              <div className='book-text'>
                {tr('FRONTEND.WELCOME.MODAL.BOOK_TEXT', 'Book a Meeting to Find Out More')}
              </div>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.SCHEDULE.BUTTON', 'Schedule Now')}
                className='schedule-button'
                onClick={() => window.location.replace('http://calendly.com/burak-uygun')}
              />
              <Pointer />
            </div>
          </BookMeetingWrapper>
        )}
      </ModalContainer>
    </>
  )
}

export default WelcomeModal

const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
`

const BookMeetingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  padding: 1rem 2rem 0;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;

  border-radius: 1rem;

  .calendar {
    align-self: end;
    display: none;

    @media (min-width: 480px) {
      display: inline-block;
    }
  }

  .book-left-side {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;

    padding: 2.5rem 0;

    @media (min-width: 480px) {
      align-items: flex-start;
    }
    .book-text {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
    }
    .schedule-button {
      background-color: #676767;
      border-radius: 4px;
      font-size: 1.4rem;

      height: 4rem;
      width: 100%;

      @media (min-width: 480px) {
        max-width: 20rem;
      }
    }
    svg {
      position: absolute;
      bottom: 4px;
      right: 0;

      @media (min-width: 480px) {
        right: 75px;
      }
    }
  }
`

const WelcomeModalWrapper = styled.div`
  width: 100%;
  max-width: 64rem;
  padding: 4rem;

  border-radius: 1rem;
  background-color: #ffffff;
  .welcome-modal-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    text-align: center;
    .title {
      font-size: 2.4rem;
      font-weight: 400;
    }
    .welcome-text {
      padding: 1rem 0;
      font-size: 1.4rem;

      &.italic {
        font-style: italic;
      }
    }
    .buttons-wrapper {
      margin-top: 3rem;
      button:nth-of-type(2) {
        margin-top: 1rem;
      }
    }
  }
  @media (max-width: 480px) {
    margin: auto;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  .round-square-container {
    margin: 0 0.5rem;
  }
  @media (max-width: 480px) {
    flex-flow: column nowrap;
    div:nth-of-type(2) {
      margin: 1rem 0;
    }
  }
`
