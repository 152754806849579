// noinspection DuplicatedCode,CssUnusedSymbol

import { useState, useEffect, useCallback, Suspense } from 'react'
import { Route, Switch, Redirect, RouteProps } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { Toaster } from 'react-hot-toast'
import { Preloader } from 'mmfintech-portal-commons'
import { ErrorBoundary } from 'react-error-boundary'
import { CookiesProvider, useCookies } from 'react-cookie'
import { ContentWrapper, CookieConsent, Header, ModalDialog, Otp, OtpLegacy } from './components'

import styled, { ThemeProvider } from 'styled-components'
import { withTranslation } from 'react-i18next'

import { GlobalContextProvider, isMobileDevice, isValidObject, OtpContext } from 'mmfintech-commons'

import { actions, useAppInitializer, useAppSelector, useLazyErrorLoggingQuery } from 'mmfintech-backend-api'

import theme from './theme'
import routes from './routes'
import settings from './settings'

import ErrorIcon from './images/icons/error.svg?react'

const AppInner = () => {
  const [cookies, setCookie] = useCookies(['cookie.consent'])

  const [cookieConsent, setCookieConsent] = useState(null)
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useAppInitializer(settings.languages)

  const { show } = useSelector(
    ({ user: { selectedLanguage }, otp: { show } }: any) => ({ show, selectedLanguage }),
    shallowEqual
  )

  const queryChallenge = useAppSelector(state => state.challenge)

  const toggle = useCallback(() => {
    if (window.innerWidth <= 1024) {
      setMobileMenuToggle(!mobileMenuToggle)
      document.getElementsByTagName('body')[0].classList.toggle('locked')
    }
  }, [mobileMenuToggle])

  const dispatch = useDispatch()

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  const reportWindowSize = () => {
    dispatch(actions.common.setScreenWidth(window.innerWidth))
    setScreenWidth(window.innerWidth)

    if (screenWidth > 1024 && mobileMenuToggle) {
      toggle()
    }

    document.getElementsByTagName('body')[0].classList.remove('locked')
  }

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const globalContext = { modalHide, modalShow }

  useEffect(() => {
    window.addEventListener('load', reportWindowSize)
    window.addEventListener('resize', reportWindowSize)
    window.addEventListener('orientationchange', reportWindowSize)
    reportWindowSize()

    return () => {
      window.removeEventListener('load', reportWindowSize)
      window.removeEventListener('resize', reportWindowSize)
      window.removeEventListener('orientationchange', reportWindowSize)
    }

    // eslint-disable-next-line
  }, [dispatch, toggle, screenWidth, mobileMenuToggle])

  useEffect(() => {
    if (show || (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId'))) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false,
          onClose: () => {
            dispatch(actions.otp.cleanup())
          }
        },
        content: !queryChallenge?.challengeId ? <OtpLegacy /> : <Otp />
      })
    } else {
      modalVisible && modalHide()
    }
  }, [show, queryChallenge])

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <ContentWrapper>
              <Header />

              <Switch>
                {routes.map((route, index) => {
                  const { component, exact, path, redirect } = route

                  if (redirect) {
                    return (
                      <Route key={index} exact={exact} path={path}>
                        <Redirect to={route.redirect} />
                      </Route>
                    )
                  }

                  if (component) {
                    return <CustomRoute key={index} path={path} exact={exact} component={component} />
                  }

                  return null
                })}
              </Switch>

              {isValidObject(cookieConsent) || isMobileDevice() ? null : <CookieConsent setCookie={setCookie} />}

              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
            </ContentWrapper>

            <Toaster
              position='top-right'
              containerStyle={{
                top: '10rem',
                zIndex: '10010'
              }}
              toastOptions={{
                className: '',
                style: {
                  color: '#000000',
                  fontFamily: 'inherit',
                  fontSize: '1.4rem',
                  fontStyle: 'normal',
                  padding: '1.5rem',
                  borderRadius: '0'
                },
                success: {}
              }}
            />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>An unexpected error occurred</p>
        <TryAgainButton type='button' onClick={resetErrorBoundary}>
          Go back to the home page
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

const ThisApp = withTranslation()(AppInner)

const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route {...rest} render={props => <Component {...props} />} />
)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          return logError({ level: 'ERROR', componentStack, message: err.toString() })
        }}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
